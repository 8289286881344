import { PLACEMENTS_API_ROOT } from "./constants";

export const loadScript = (url: string, iframeWindow: Window) => {
  return new Promise((resolve) => {
    const { document } = iframeWindow;
    const script = document.createElement("script");
    script.src = url;
    document.querySelector("head").appendChild(script);
    script.addEventListener("load", () => {
      resolve(true);
    });
  });
}

export const log = (event: string, publisherId: string, placementId: string) => {
  console.log("mia: %cLogger initialized", "color: #4dc97b", event, publisherId, placementId);
  try {
    fetch(`${PLACEMENTS_API_ROOT}/tracking/${publisherId}/${placementId}/${event}`);
  } catch (error) {
    console.log("mia: %cLogger error", "color: #ff0000", error);
  }
}

export const throttle = (limit: number, callback: () => void) => {
  let waiting = false;
  return function () {
    if (!waiting) {
      callback();
      waiting = true;
      setTimeout(() => {
        waiting = false;
      }, limit);
    }
  }
}