import handlePassback from "@player/passbacks";
import setupTargets from "@wrapper/setupTargets";
import styles from "@wrapper/styles.sass";
import { Config, Insertion } from "@/types";

const getTargets = (insertionConfig: Insertion, containers: HTMLElement[]) => {
	const insertedElements = [];
	for (const element of containers) {
    const id = insertionConfig.allowMultiple
			? `mia-outstream-${Math.random().toString(36).substring(2, 12)}` // 10 chars random id from 0-9 and a-z
			: `mia-outstream-${insertionConfig.id}`;

    const splitboxDiv = `<div class="${id}_onscreen mia-outstream" style="display: grid;"><div id="${id}" class="${id}" style="width: 100%; text-align: center"></div></div>`;
    if (insertionConfig.type === "DIV_AFTER") {
      if (element.nextElementSibling?.classList.contains(".mia-outstream")) continue; // prevent duplicate insertion on infinite scroll
			element.insertAdjacentHTML("afterend", splitboxDiv);
    } else if (insertionConfig.type === "DIV_INSIDE") {
      if (element.querySelector(".mia-outstream")) continue;
			element.insertAdjacentHTML("afterbegin", splitboxDiv);
    } else if (insertionConfig.type === "DIV_BEFORE") {
      if (element.previousElementSibling?.classList.contains(".mia-outstream")) continue;
			element.insertAdjacentHTML("beforebegin", splitboxDiv);
    } else {
      if (element.nextElementSibling?.classList.contains(".mia-outstream")) continue;
			element.insertAdjacentHTML("beforebegin", splitboxDiv);
		}
		const insertedElement = element.parentElement.querySelector(`#${id}`);
		if (!insertedElement) continue;
		insertionConfig.ids?.push(id);
		insertedElements.push(insertedElement);

    if (insertionConfig.allowMultiple === false) break;
	}
	return insertedElements;
}

class AutoInsertion {
  config: Config;
  insertTries: number;

  constructor(config: Config) {
    this.config = config;
    this.insertTries = 0;
    this.setupAutoInsertion();
  }

  setupAutoInsertion() {
    const splitBoxDivs = this.getSplitBoxDivs();

    if (splitBoxDivs) {
      setupTargets(this.config, splitBoxDivs);
    } else {
      if (this.insertTries < 3) {
        this.insertTries += 1;
        setTimeout(() => this.setupAutoInsertion(), 500);
        return;
      } else {
        console.warn("mia: Auto insertion failed", this.config.id, this.config.insertions);
        this.config.log("excluded_by_insertion");
        const scriptElement = document.querySelector(`script[src*="pub.js"]`);
        this.config.topWindow = window;
        const container = document.createElement("div");
        container.id = `mia-outstream-${this.config.id}`;
        scriptElement.after(container);
        handlePassback(this.config, container);
        return;
      }
    }
  }

  getSplitBoxDivs() {
    let splitBoxDivs = [];
    for (const insertionConfig of this.config.insertions) {
      if (!insertionConfig.selector) {
        console.log("mia: Insertion config rejected because selector not specified", this.config.id, insertionConfig);
        continue;
      }
      let elements = window.miaLib(insertionConfig.selector).elements;
      if (elements.length > 0) {
        this.config.topWindow = window;
        document.body.insertAdjacentHTML("beforeend", `<style>${styles}</style>`);
      } else if (window.parent) {
        elements = window.miaLib(insertionConfig.selector, window.parent.document).elements;
        if (elements.length > 0) {
          this.config.topWindow = window.parent;
          this.config.topWindow.document.head.insertAdjacentHTML("beforeend", `<style>${styles}</style>`);
        }
      }

      if (elements.length === 0) {
        console.log("mia: Insertion config rejected because cannot find elements", this.config.id, insertionConfig);
        continue;
      }

      insertionConfig.id = this.config.id;
      insertionConfig.ids = [];
      splitBoxDivs = getTargets(insertionConfig, elements);

      if (splitBoxDivs.length > 0) {
        console.log("mia: %cInsertion config selected", "color: #4dc97b", this.config.id, "\nInsertion config:", insertionConfig, "\nTarget elements:", splitBoxDivs);
        this.config.log("eligible_by_insertion");
        if (insertionConfig.cssStyles) {
          this.config.insertionCSS = Object.entries(insertionConfig.cssStyles).reduce((prev, [key, value]) => {
            if (key === "max-width") {
              this.config.maxWidth = value;
              return prev;
            }
            return prev + `${key}:${value};`
          }, "");
        }
        return splitBoxDivs;
      }
    }
  }
}

export { AutoInsertion as default, getTargets };