import eventDispatcher from "@wrapper/events";
import { setupFooterBanner, setupLeaveBehindBanner } from "@player/banners";
import { setStickyDesktop, setStickyMobileTop } from "@player/sticky";
import createJWPlayer from "@player/jwplayer";
import handlePassback from "@player/passbacks";
import { Config } from "@/types";

const updateHeight = (config: Config, document: Document) => {
  // if (config.footerBannerCompanion?.html || config.footerBannerCompanion?.iframe) {
  //   const proportion = config.container.offsetWidth / config.footerBannerCompanion.width;
  //   if (proportion < 1) {
  //     document.querySelector<HTMLElement>(".mia-footer .mia-banner").style.transform = `scale(${proportion})`;
  //     document.querySelector<HTMLElement>(".mia-footer").style.height = `${document.querySelector(".mia-footer .mia-banner").getBoundingClientRect().height}px`;
  //   }
  // }

  const height = document.querySelector<HTMLElement>(".wrapper").offsetHeight;
  console.log("mia: Updated height:", height);
  if (config.container.querySelector(".mia-iframe.mia-sticky-desktop")) {
    (config.container.querySelector(".mia-iframe") as HTMLElement).style.height = `${height}px`;
  } else {
    config.container.style.height = `${height}px`;
    config.maxHeight = height;
  }

  if (config.displayOptions.fixHeight && config.stickyMode !== "top") {
    config.container.parentElement.style.height = `${height}px`;
  }
}

const setupPlayer = async (config: Config, iframeWindow: Window) => {
  const { container } = config;
  const { document } = iframeWindow;
  const player = await createJWPlayer(config, iframeWindow).setup();
  const { jwplayer } = player;
  config.updateHeight = (height: number) => jwplayer.resize("100%", height);
  config.isMobile = jwplayer.getEnvironment().OS.mobile;
  const events = eventDispatcher.attachEvents(player);
  config.topWindow._MiaAds.mapEvents(events);

  if (config.footerBanner.enabled) {
    setupFooterBanner(
      config,
      config.companions.filter(({ position }) => position === "FOOTER" || position === "FOOTER_MOBILE"),
      iframeWindow,
    );
  }

  jwplayer
    .on("resize", () => {
      updateHeight(config, document);
    })
    .once("beforePlay", () => {
      if (config.maxWidth) config.topWindow.document.querySelector("head").insertAdjacentHTML("beforeend", `<style>#${config.container.id} { max-width: ${config.maxWidth}}</style>`);
      // set padding to show JWPlayer claim text
      document.querySelector<HTMLElement>(".wrapper").style.paddingTop = config[config.mode].claimTextEnabled && !!config.displayOptions.claimText && !config.topWindow.ampSeen ? "32px" : "0px";
      if (config.mode === "outstream") {
        const { mobile, desktop } = config.displayOptions.sticky;
        if (config.isMobile) {
          if (mobile.enabled) {
            if (config.displayOptions.sticky.mobile.type === "top") {
              setStickyMobileTop(player, mobile, container, config.topWindow);
              config.stickyMode = "top";
            } else if (mobile.type === "bottom") {
              container.querySelector(".mia-iframe").classList.add("mia-sticky-bottom");
              console.log("mia: %cSticky bottom floating mode initialized.", "color: #4dc97b");
            }
          }
        } else if (desktop.enabled) {
          setStickyDesktop(config, player, desktop, container);
        }
      }
    })
    .once("adImpression", ({ mediafile }) => {
      config.mediafile = mediafile?.file;
      container.parentElement.classList.add("mia-opened");
      container.parentElement.style.maxHeight = `${config.maxHeight + 1000}px`;
      if (config.insertionCSS) {
        config.topWindow.document.querySelector("head").insertAdjacentHTML("beforeend", `<style>#${config.container.id} {${config.insertionCSS}}</style>`);
      }
    })
    .on("adPlay", ({ clickThroughUrl }) => {
      config.clickThroughUrl = clickThroughUrl;
    });

  jwplayer.once("complete", () => {
    const leaveBehindCompanions = config.companions?.filter(({ position }) => position === "LEAVE_BEHIND");
    if (config.leaveBehindBanner.enabled && (leaveBehindCompanions?.length > 0 || config.leaveBehindBanner.imgUrl || config.leaveBehindBanner.script)) {
      if (config.displayOptions.sticky.desktop.enabled || config.displayOptions.sticky.mobile.enabled) {
        const iframe: HTMLIFrameElement = container.querySelector(".mia-iframe");
        iframe.classList.remove("mia-sticky-desktop", "mia-sticky-top", "mia-sticky-bottom");
        iframe.style.removeProperty("max-width");
        iframe.style.removeProperty("height");
        config.topWindow.removeEventListener("scroll", player.floatingScrollListener);
      }
      setupLeaveBehindBanner(
        config,
        player,
        leaveBehindCompanions,
        iframeWindow
      );
    } else if (config.displayOptions.replay) {
      document.querySelector(".jw-display-controls").insertAdjacentHTML("beforeend", `<a class="jw-display-learnmore jw-text jw-hidden" target="_blank" href="${config.clickThroughUrl}">${config.localization.more}</a>`);
      player.container.classList.add("jw-tag-replayable");
    } else {
      if (config.displayOptions.keepOpen) {
        document.querySelector(".jwplayer .jw-media").insertAdjacentHTML("beforeend", `
          <div class="mia-post-leave-behind-banner mia-post-keep-open visible">
            <p class="jw-text">${config.localization.thanks}</p>
          </div>
        `);
      } else {
        player.remove();
      }
    }
  });

  jwplayer
    .on("adError", (error: { adErrorCode: string }) => {
      handlePassback(config, config.container);
      console.error(`JWPlayer error code: ${error.adErrorCode}`, error);
    });
}

export default setupPlayer;